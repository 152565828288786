import styles from "./Privacy.module.css";

import importImgs from "../../../utils/importImgs";

const Privacy = (props) => {
  const { playVideo } = props;

  /** images imported from "/image/home" directory, property name is the same as the image file name */
  const homeImages = importImgs(
    require.context("../../../image/home", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div className={styles.privacy}>
      <div className={styles.pri_container}>
        <div className={styles.pri_icon}>
          <img
            src={homeImages["privacy_icon.svg"]}
            alt="an icon of a bank, representing data privacy"
          />
        </div>
        <div className={`${styles.pri_copy} g_font_copy`}>
          <div className={styles.pri_title}>
            PRIVACY IS AT THE FOCUS OF EVERYTHING WE DO
          </div>
          <ul>
            <li>
              Our irisGO™ data collection device never collects private
              information
            </li>
            <li>
              Privacy information such as faces, cars and bikes are redacted at
              source and is never collected
            </li>
          </ul>
          <p>
            New technologies promise to help municipalities better manage urban
            environments and deliver services in a more effective and efficient
            way. They can help to make communities more liveable, sustainable,
            and fair. Many involve the collection and use of large amounts of
            information, including personal information. Cities or
            municipalities that use these connected technologies are often
            described as “smart cities.”
          </p>
          <p>
            The Office of the Information and Privacy Commissioner of Ontario
            (IPC) provides independent oversight of the Municipal Freedom of
            Information and Protection of Privacy Act (MFIPPA). This act
            protects the privacy of personal information by setting rules for
            its collection, use and disclosure by municipalities and municipal
            institutions. These rules also give individuals the right to access
            their own personal information.
          </p>
          <p>IRIS R&D Group is MFIPPA compliant.</p>
          <div
            className={`${styles.pri_video}`}
            onClick={() => playVideo("privacy.mp4")}
          >
            <img
              src={homeImages["privacy_video_cover.jpg"]}
              alt="irisGO vision auto redacting people's faces for privacy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
