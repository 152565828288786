import styles from "./VideoModal.module.css";
import { useRef, useEffect } from "react";

const VideoModal = (props) => {
  const { videoOn, setVideoOn, videoSrc } = props;

  const videoModalRef = useRef(null);

  window.addEventListener("click", (e) => {
    if (e.target === videoModalRef.current) {
      videoModalRef.current.style.display = "none";
      setVideoOn(false);
    }
  });

  useEffect(() => {
    if (videoOn) {
      videoModalRef.current.style.display = "block";
    }
  }, [videoOn]);

  return (
    <div className={styles.modal} ref={videoModalRef}>
      <div className={styles.modalContent}>
        <button
          className={styles.close}
          onClick={() => {
            videoModalRef.current.style.display = "none";
            setVideoOn(false);
          }}
        >
          <span>&times;</span>
        </button>
          {videoOn && (
            <video
              className={styles.video}
              controls
              autoPlay="autoplay"
              muted
            >
              <source src={videoSrc} type="video/mp4" />
            </video>
          )}
      </div>
    </div>
  );
};

export default VideoModal;
