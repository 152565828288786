import { useState } from "react";
import { NavLink } from "react-router-dom";

import NavBar from "../../components/navbar/NavBar";
import Footer from "../../components/footer/Footer";
import VideoModal from "../../components/videoModal/VideoModal";

// import "../../css/googleFont.css";
import styles from "./Home.module.css";

import mainHeroMp4 from "../../image/main_hero.mp4";

import importImgs from "../../utils/importImgs";
import Testimonial from "../../components/home/testimonial/Testimonial";
import Privacy from "../../components/home/privacy/Privacy";
import GraphSection from "../../components/home/graph/GraphSection";
import AboutIRIS from "../../components/home/aboutIRIS/AboutIRIS";
import Intro from "../../components/home/intro/Intro";
import IrisGo from "../../components/home/irisGo/IrisGo";
import ThreeSolution from "../../components/home/threeSolution/ThreeSolution";
import SolutionIllust from "../../components/home/solutionIllust/SolutionIllust";

const Home = () => {
  const homeMp4 = importImgs(
    require.context("../../contents", false, /\.(mp4)$/)
  );

  // set state of the playing video
  const [videoSrc, setVideoSrc] = useState("");
  // set state of the video playing or not
  const [videoOn, setVideoOn] = useState(false);

  /**
   * function to play the video, located at given video name
   * @param {string} videoName video name
   */
  const playVideo = (videoName) => {
    setVideoSrc(videoName);
    setVideoOn(true);
  };

  return (
    <div className={styles.page_container}>
      <div className={styles.head_container}>
        <NavBar />
        <div className={styles.main_hero}>
          <div>
            <video
              preload="auto"
              autoPlay="autoplay"
              loop="loop"
              muted="muted"
              width="100%"
              height="auto"
            >
              <source src={mainHeroMp4} type="video/mp4" />
            </video>
          </div>
          <div className={styles.title_onHero}>
            <div className={styles.font_copy}>
              {/* <p>
                On demand AI-enabled Transportation, <br /> Pavement and
                Right-of-Way asset reporting technology
              </p> */}
              <p>
                CONCESSIONS, ROAD PATROL <br /> AND PAVEMENT CONDITION
                MONITORING SOLUTIONS
              </p>
            </div>
            <NavLink className={styles.demoButton} replace to="/contact">
              Ask for a Demo
            </NavLink>
          </div>
        </div>
      </div>
    
      <ThreeSolution />


      <Intro />


      <SolutionIllust />

      <div className={styles.irisgo}> 

        <IrisGo playVideo={playVideo} />

        <AboutIRIS />

        <GraphSection />   


        <Privacy playVideo={playVideo} />


        <VideoModal
          videoOn={videoOn}
          setVideoOn={setVideoOn}
          videoSrc={homeMp4[videoSrc]}
        />

        <Testimonial />

        <Footer />
      </div>
    </div>
  );
};

export default Home;
