import styles from "./SolutionIllust.module.css";
import { useRef, useEffect } from "react";

import importImgs from "../../../utils/importImgs";
import useAnimeAutoPlayHover from "../../../hooks/useAnimeAutoPlayHover";

const SolutionIllust = () => {
  /** images imported from "/image/home" directory, property name is the same as the image file name */
  const homeImages = importImgs(
    require.context("../../../image/home", false, /\.(png|jpe?g|svg)$/)
  );

  // DOM elements for four illust
  const s_illust_redbox_icon_ref = useRef(null);
  const s_illust_pulldown_icon_ref = useRef(null);
  const s_illust_repaired_icon_ref = useRef(null);
  const s_illust_fleet_icon_ref = useRef(null);
  const target_b_icon_ref = useRef(null);
  const target_b_copy_ref = useRef(null);
  const target_b_illust_ref = useRef(null);
  const target_p_icon_ref = useRef(null);
  const target_p_copy_ref = useRef(null);
  const target_p_illust_ref = useRef(null);
  const target_r_icon_ref = useRef(null);
  const target_r_copy_ref = useRef(null);
  const target_r_illust_ref = useRef(null);
  const target_f_icon_ref = useRef(null);
  const target_f_copy_ref = useRef(null);
  const target_f_illust_ref = useRef(null);

  // props for useAnimeAutoPlayHover custom hook
  const animeDomElementsRef = [
    [target_b_copy_ref, target_b_illust_ref],
    [target_p_copy_ref, target_p_illust_ref],
    [target_r_copy_ref, target_r_illust_ref],
    [target_f_copy_ref, target_f_illust_ref],
  ];
  const triggerDomElementsRef = [
    s_illust_redbox_icon_ref,
    s_illust_pulldown_icon_ref,
    s_illust_repaired_icon_ref,
    s_illust_fleet_icon_ref,
  ];
  const animeEffects = [
    {
      opacity: [
        { value: 1, duration: 100, delay: 2000 },
        { value: 0, duration: 100, delay: 2000 },
      ],
    },
  ];
  const mouseOverEffects = (copy, illust) => {
    copy.style.opacity = 1;
    illust.style.opacity = 1;
  };
  const mouseOutEffects = (copy, illust) => {
    copy.style.opacity = 0;
    illust.style.opacity = 0;
  };
  const mouseOverAnimeDomElementsRef = {
    copy: [
      target_b_copy_ref,
      target_p_copy_ref,
      target_r_copy_ref,
      target_f_copy_ref,
    ],
    illust: [
      target_b_illust_ref,
      target_p_illust_ref,
      target_r_illust_ref,
      target_f_illust_ref,
    ],
  };
  const hookProps = {
    animeDomElementsRef,
    triggerDomElementsRef,
    animeEffects,
    mouseOverEffects,
    mouseOutEffects,
    mouseOverAnimeDomElementsRef,
  };

  const { autoPlayAnime, illustMouseover } = useAnimeAutoPlayHover(hookProps)

  // when component mounts, apply the defined functions
  useEffect(() => {
    autoPlayAnime()
    illustMouseover()
  }, []);

  return (
    <div className={styles.solution_illust}>
      <div className={styles.g_font_title}>
        Smart data collection and GIS-Centric reporting
      </div>

      <div className={styles.s_illust_bg}>
        <img
          className={styles.s_lllust_town}
          src={homeImages["s_illust_town_new.svg"]}
          alt="vextor illustration of a town with buildings"
        />
        <div>
          <div className={styles.s_illust_redbox} ref={target_b_illust_ref}>
            <img
              src={homeImages["s_illust_detectBox.svg"]}
              alt="redbozes showcasing the AI video detection"
            />
          </div>
          <div className={styles.s_illust_pulldown} ref={target_p_illust_ref}>
            <img
              src={homeImages["s_illust_sign_pulldown.svg"]}
              alt="A red street sign that has fallen down"
            />
          </div>
          <div className={styles.s_illust_repaired} ref={target_r_illust_ref}>
            <img
              src={homeImages["s_illust_repaired.svg"]}
              alt="A red street sign that has been repaired"
            />
          </div>

          <div className={styles.s_illust_fleet} ref={target_f_illust_ref}>
            <img
              src={homeImages["fleet-tracking-truck.svg"]}
              alt="A red street sign that has been repaired"
            />
          </div>
        </div>
      </div>

      <div className={styles.s_illust_icon_container}>
        <div className={styles.s_illust_copy_wrap}>
          <div className={styles.s_illust_redbox_copy} ref={target_b_copy_ref}>
            <h2 className={styles.g_font_subtitle}>
              irisGO™ Data Collection Mapping
            </h2>
            <p className={styles.g_font_copy}>
              Identification of road defects and right-of-way asset inventory
            </p>
          </div>
          <div
            className={styles.s_illust_pulldown_copy}
            ref={target_p_copy_ref}
          >
            <h2 className={styles.g_font_subtitle}>Asset Monitoring</h2>
            <p className={styles.g_font_copy}>
              AI-driven identification of changes and issues over time
            </p>
          </div>
          <div
            className={styles.s_illust_repaired_copy}
            ref={target_r_copy_ref}
          >
            <h2 className={styles.g_font_subtitle}>Predictive Maintenance</h2>
            <p className={styles.g_font_copy}>
              Feeding intelligence into the strategic capital planning process
            </p>
          </div>

          <div className={styles.s_illust_fleet_copy} ref={target_f_copy_ref}>
            <h2 className={styles.g_font_subtitle}>Fleet Management</h2>
            <p className={styles.g_font_copy}>
              Manage your fleets using AI-enabled route optimization
            </p>
          </div>
        </div>

        <div className={styles.s_illust_icon_wrap}>
          <div
            className={styles.s_illust_redbox_icon}
            ref={s_illust_redbox_icon_ref}
          >
            <div className={styles.b_icon}>
              <img
                ref={target_b_icon_ref}
                width="124px"
                src={homeImages["s_illust_irisgo_icon.svg"]}
                alt="a vector illustration of the irisGO"
              />
            </div>
          </div>

          <div
            className={styles.s_illust_pulldown_icon}
            ref={s_illust_pulldown_icon_ref}
          >
            <div className={styles.p_icon}>
              <img
                ref={target_p_icon_ref}
                width="124px"
                src={homeImages["s_illust_sign_pulldown_icon.svg"]}
                alt="A red street sign that has fallen down"
              />
            </div>
          </div>

          <div
            className={styles.s_illust_repaired_icon}
            ref={s_illust_repaired_icon_ref}
          >
            <div className={styles.r_icon}>
              <img
                ref={target_r_icon_ref}
                width="124px"
                src={homeImages["s_illust_repaired_icon.svg"]}
                alt="A red street sign that has been repaired"
              />
            </div>
          </div>

          <div
            className={styles.s_illust_fleet_icon}
            ref={s_illust_fleet_icon_ref}
          >
            <div className={styles.r_icon}>
              <img
                ref={target_f_icon_ref}
                width="124px"
                src={homeImages["s_illust_fleet_icon.svg"]}
                alt="A red street sign that has been repaired"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionIllust;
