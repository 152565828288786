import styles from "./IrisGo.module.css";
import { useRef } from "react";

import importImgs from "../../../utils/importImgs";

const IrisGo = (props) => {
  const { playVideo } = props;

  /** images imported from "/image/home" directory, property name is the same as the image file name */
  const homeImages = importImgs(
    require.context("../../../image/home", false, /\.(png|jpe?g|svg)$/)
  );

  // obtain DOM elements with useRef hook
  const drawStartRef = useRef(null);
  const pathRef = useRef(null);
  const reddotRef = useRef(null);
  // DOM elements for red dot meeting signs
  const potholeRef = useRef(null);
  const lightPostRef = useRef(null);
  const gabageRef = useRef(null);
  const graffitiRef = useRef(null);
  const trackRef = useRef(null);
  const planningRef = useRef(null);
  const workRef = useRef(null);
  const statusRef = useRef(null);
  const mapRef = useRef(null);
  const measureRef = useRef(null);
  const predictRef = useRef(null);

  // Irisgo Section Reddot animation - Start
  const scrollCheck = () => {
    const veiwPortCenter =
      Math.round(window.scrollY) + window.innerHeight / 1.4; // heigh top to red dot

    if (drawStartRef.current) {
      const rect = drawStartRef.current.getBoundingClientRect();
      const drawStartOffset = {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
      };

      if (veiwPortCenter >= Math.round(drawStartOffset.top)) {
        // drawing red dot
        positionRedDot();
        reddotMeetSign();
      }
      if (veiwPortCenter < Math.round(drawStartOffset.top)) {
        // reset red dot
        positionRedDot(0);
      }
    }
  };

  // function to position the red dot on the page
  const positionRedDot = (el) => {
    if (drawStartRef.current) {
      const rect = drawStartRef.current.getBoundingClientRect();
      const drawStartPosition = {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
      };
      const drawElementStyle = window.getComputedStyle(drawStartRef.current);

      const veiwPortCenter2 =
        Math.round(window.scrollY) + window.innerHeight / 1.2; // heigh from top to red dot
      let scrollPercentage = (
        (veiwPortCenter2 - Math.round(drawStartPosition.top)) /
        parseInt(drawElementStyle.height)
      ).toFixed(5);

      const dotSpeed = 1; // Adjudt speed of Reddot
      const adjustSpeed = scrollPercentage * dotSpeed; // New Scroll Percentatge adusted with above dotSpeed.

      if (pathRef.current && adjustSpeed) {
        const pathLen = pathRef.current.getTotalLength(); // length of the line (8528)
        // Get the position of a point at <scrollPercentage> along the pathRef.
        const pt = pathRef.current.getPointAtLength(pathLen * adjustSpeed); // New position of red dot with adjusted speed.

        if (reddotRef.current) {
          reddotRef.current.setAttribute(
            "transform",
            `translate(${pt.x + 26.5}, ${pt.y - 2107})`
          );
        }

        if (veiwPortCenter2 < drawStartPosition) {
          scrollPercentage = el; // red dot position reset to 0
        }
      }
    }
  };

  //Signs' texts show up when meeting the red dot
  const reddotMeetSign = () => {
    if (drawStartRef.current) {
      const rect = drawStartRef.current.getBoundingClientRect();
      const drawStartPosition = {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
      };
      const drawElementStyle = window.getComputedStyle(drawStartRef.current);

      let veiwPortCenter2 =
        Math.round(window.scrollY) + window.innerHeight / 1.2; // heigh from top to red dot
      let scrollPercentage = (
        (veiwPortCenter2 - Math.round(drawStartPosition.top)) /
        parseInt(drawElementStyle.height)
      ).toFixed(5);

      const dotSpeed = 1; // Adjudt speed of Reddot
      const adjustSpeed = scrollPercentage * dotSpeed; // New Scroll Percentatge adusted with above dotSpeed.

      if (pathRef.current && adjustSpeed) {
        const pathLen = pathRef.current.getTotalLength();

        const signPos = {
          first: {
            boundary: { h: 3831, l: 2700 },
            pothole: 3010, // Y pos
            lightPost: 3260, // Y pos
            gabage: 642, // X pos
            graffiti: 342, // X pos
          },
          second: {
            // Y pos
            boundary: { h: 5367, l: 4243 },
            track: 4480,
            planning: 4745,
            work: 4978,
          },
          third: {
            // X pos
            boundary: 6200,
            status: 140,
            map: 380,
            measure: 620,
            predict: 855,
          },
        };

        const pt = pathRef.current.getPointAtLength(pathLen * adjustSpeed); // New position of red dot with adjusted speed.
        const ptx = Math.floor(pt.x);
        const pty = Math.floor(pt.y);

        if (pty > signPos.first.boundary.l && pty < signPos.first.boundary.h) {
          if (pty > signPos.first.pothole) {
            if (potholeRef.current) {
              potholeRef.current.style.opacity = 1;
              potholeRef.current.style.transition = "all 1.5s";
            }
          }
          if (pty > signPos.first.lightPost) {
            if (lightPostRef.current) {
              lightPostRef.current.style.opacity = 1;
              lightPostRef.current.style.transition = "all 1.5s";
            }
          }
          if (ptx < signPos.first.gabage) {
            if (gabageRef.current) {
              gabageRef.current.style.opacity = 1;
              gabageRef.current.style.transition = "all 1.5s";
            }
          }
          if (ptx < signPos.first.graffiti) {
            if (graffitiRef.current) {
              graffitiRef.current.style.opacity = 1;
              graffitiRef.current.style.transition = "all 1.5s";
            }
          }
        }
        if (
          pty > signPos.second.boundary.l &&
          pty < signPos.second.boundary.h
        ) {
          if (pty > signPos.second.track) {
            if (trackRef.current) {
              trackRef.current.style.opacity = 1;
              trackRef.current.style.transition = "all 1.5s";
            }
          }
          if (pty > signPos.second.planning) {
            if (planningRef.current) {
              planningRef.current.style.opacity = 1;
              planningRef.current.style.transition = "all 1.5s";
            }
          }
          if (pty > signPos.second.work) {
            if (workRef.current) {
              workRef.current.style.opacity = 1;
              workRef.current.style.transition = "all 1.5s";
            }
          }
        }
        if (pty > signPos.third.boundary) {
          if (ptx > signPos.third.status) {
            if (statusRef.current) {
              statusRef.current.style.opacity = 1;
              statusRef.current.style.transition = "all 1.5s";
            }
          }
          if (ptx > signPos.third.map) {
            if (mapRef.current) {
              mapRef.current.style.opacity = 1;
              mapRef.current.style.transition = "all 1.5s";
            }
          }
          if (ptx > signPos.third.measure) {
            if (measureRef.current) {
              measureRef.current.style.opacity = 1;
              measureRef.current.style.transition = "all 1.5s";
            }
          }
          if (ptx > signPos.third.predict) {
            if (predictRef.current) {
              predictRef.current.style.opacity = 1;
              predictRef.current.style.transition = "all 1.5s";
            }
          }
        }
      }
    }
  };

  // window.addEventListener("scroll", scrollCheck);
  window.addEventListener("scroll", () => {
    scrollCheck();
  });

  return (
    <div>
      <div className={styles.irisgo_pattern_first}>
        <img
          src={homeImages["irisgo_pattern.svg"]}
          role="presentation"
          alt=""
        />
      </div>
      <div className={styles.irisgo_pattern_second}>
        <img
          src={homeImages["irisgo_pattern.svg"]}
          role="presentation"
          alt=""
        />
      </div>
      <div className={styles.irisgo_pattern_third}>
        <img
          src={homeImages["irisgo_pattern.svg"]}
          role="presentation"
          alt=" "
        />
      </div>

      <div id="line" className={styles.irisgo_container}>
        <div className={styles.irisgo_line}>
          <div className={styles.irisgo_device}>
            <img
              src={homeImages["irisgo_device_icon.svg"]}
              alt="An icon of the irisGO device."
            />
            <h2 className={styles.g_font_subtitle}>
              A SMART CITIES HARDWARE
              <br />
              AND DATA PLATFORM SOLUTION
            </h2>
            <div className={styles.irisgo_top_copy}>
              <p className={styles.g_font_subtitle}>
                Our plug and play award winning irisGO™ data collection device
                is integrated with artificial intelligence for real-time road
                condition monitoring. <br />
                Our technology utilizes accelerometers to detect road roughness
                for pavement condition index (PCI) reporting.
              </p>
              <p></p>
            </div>
          </div>

          <div className={styles.irisgo_device_pic}>
            <img
              width="15%"
              src={homeImages["irisgo_device_pic.jpg"]}
              alt="the irisGO device mounted on a service vehicle"
            />
          </div>
          <div className={styles.irisgo_mid_copy}>
            <img
              width="100%"
              src={homeImages["irisgo_mid_copy.svg"]}
              alt="if you can see it, irisGO can track it"
            />
          </div>
          <div className={styles.draw_start}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1281.614 5001.393"
            >
              <path
                d="M-1.467,2139.99l1062.77-2.624s71.413-6.255,122.027,42.972,46.266,111.977,46.266,111.977v72.278s.307,58.6-46.57,102.277-121.723,38.2-121.723,38.2H953.979s-51.2-5.333-87.467,33.066-30.933,102.4-30.933,102.4V3545.8s4.681,70.909-32.065,112.51-96.026,33.727-96.026,33.727H157.521s-66.014-5.3-102.068,34.913-30.92,96.075-30.92,96.075v54.649s-3.746,44.823,35.774,85.036,97.022,40.4,97.022,40.4H319.734s79.62-1.058,121.22,46.782,36.626,117.9,36.626,117.9V5210.67s6.754,69.667-43.913,117.667-108.639,41.789-108.639,41.789H155.509S75.4,5362.6,36.109,5406.29.907,5515.38.907,5515.38v806.109s-4.32,77.239,51.147,133.4,139.314,52.98,139.314,52.98H981.706s67.361-4.808,105.829,37.023,31.467,86.4,31.467,86.4v187.066"
                transform="translate(26.755 -2112.059)"
                fill="none"
                stroke="#efeeea"
                strokeLinecap="round"
                strokeWidth="50"
              />
            </svg>
          </div>
          <div id="route" className={styles.drawStart} ref={drawStartRef}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1281.614 5001.393"
            >
              <path
                id="path"
                d="M-1.467,2139.99l1062.77-2.624s71.413-6.255,122.027,42.972,46.266,111.977,46.266,111.977v72.278s.307,58.6-46.57,102.277-121.723,38.2-121.723,38.2H953.979s-51.2-5.333-87.467,33.066-30.933,102.4-30.933,102.4V3545.8s4.681,70.909-32.065,112.51-96.026,33.727-96.026,33.727H157.521s-66.014-5.3-102.068,34.913-30.92,96.075-30.92,96.075v54.649s-3.746,44.823,35.774,85.036,97.022,40.4,97.022,40.4H319.734s79.62-1.058,121.22,46.782,36.626,117.9,36.626,117.9V5210.67s6.754,69.667-43.913,117.667-108.639,41.789-108.639,41.789H155.509S75.4,5362.6,36.109,5406.29.907,5515.38.907,5515.38v806.109s-4.32,77.239,51.147,133.4,139.314,52.98,139.314,52.98H981.706s67.361-4.808,105.829,37.023,31.467,86.4,31.467,86.4v187.066"
                transform="translate(26.755 -2112.059)"
                fill="none"
                stroke="red"
                strokeLinecap="round"
                strokeWidth="0"
                ref={pathRef}
              />

              <circle
                r="16"
                id="reddot"
                cy="-5"
                cx="0"
                fill="red"
                fillOpacity="1"
                fillRule="evenodd"
                transform="translate(25 31)"
                ref={reddotRef}
              />
            </svg>
          </div>

          <div className={styles.irisgo_firt_sign_group}>
            <div className={styles.irisgo_sign_first}>
              <img
                src={homeImages["irisgo_sign_first.svg"]}
                alt="irisGO data collection mapping"
              />
            </div>

            <div className={styles.igo_first_pothole}>
              <img
                src={homeImages["igo_first_pothole.svg"]}
                alt="An icon of a pothole in the middle of the road."
              />
            </div>
            <div className={styles.igo_first_pothole_copy} ref={potholeRef}>
              Potholes, cracks, rutting, drop-offs
            </div>

            <div className={styles.igo_first_lightpost}>
              <img
                src={homeImages["igo_first_lightpost.svg"]}
                alt="An icon of street light and street signs."
              />
            </div>
            <div className={styles.igo_first_lightpost_copy} ref={lightPostRef}>
              Light posts, signs, hydrants, guard rails, manholes
            </div>

            <div className={styles.igo_first_gabage}>
              <img
                src={homeImages["igo_first_gabage.svg"]}
                alt="An icon of a trashvan an recycling bin."
              />
            </div>
            <div className={styles.igo_first_gabage_copy} ref={gabageRef}>
              Waste set-outs, bulk items, municipal litter bins, blue bins
            </div>

            <div className={styles.igo_first_graffiti}>
              <img
                src={homeImages["igo_first_graffiti.svg"]}
                alt="A spray can representing graffity and other vandalism."
              />
            </div>
            <div
              className={styles.igo_first_graffiti_copy}
              style={{ width: "220px" }}
              ref={graffitiRef}
            >
              Graffiti, shared bike stations, bus shelters, electrical cabinets
            </div>
          </div>

          <div className={styles.irisgo_second_sign_group}>
            <div className={styles.irisgo_sign_second}>
              <img
                src={homeImages["irisgo_sign_second.svg"]}
                alt="asset monitoring"
              />
            </div>

            <div className={styles.igo_second_track}>
              <img
                src={homeImages["igo_second_track.svg"]}
                alt="An icon of a clock representing irisGO ability to track items over time."
              />
            </div>
            <div className={styles.igo_second_track_copy} ref={trackRef}>
              Track asset condition and degradation over time
            </div>

            <div className={styles.igo_second_planning}>
              <img
                src={homeImages["igo_second_planning.svg"]}
                alt="an icon of could computing"
              />
            </div>
            <div className={styles.igo_second_planning_copy} ref={planningRef}>
              Provide evidence-based data for strategic asset planning
            </div>

            <div className={styles.igo_second_work}>
              <img
                src={homeImages["igo_second_work.svg"]}
                alt="An icon of a checklist of items."
              />
            </div>
            <div className={styles.igo_second_work_copy} ref={workRef}>
              {/* Detect road issues/asset defects and dispatch work */}
              Seamless integration with life-cycle modelling systems
            </div>
          </div>

          <div className={styles.irisgo_third_sign_group}>
            <div className={styles.irisgo_sign_third}>
              <img
                src={homeImages["irisgo_sign_third.svg"]}
                alt="predictive datasets"
              />
            </div>

            <div className={styles.igo_third_status}>
              <img
                src={homeImages["igo_third_status.svg"]}
                alt="An icon of the irisCITY dashboard."
              />
            </div>
            <div className={styles.igo_third_status_copy} ref={statusRef}>
              Track status of defects and work orders
            </div>

            <div className={styles.igo_third_map}>
              <img
                src={homeImages["igo_third_map.svg"]}
                alt="An icon of a map with iris geolocation capabilitites."
              />
            </div>
            <div className={styles.igo_third_map_copy} ref={mapRef}>
              Easy access to dashboards and heat maps
            </div>

            <div className={styles.igo_third_measure}>
              <img
                src={homeImages["igo_third_measure.svg"]}
                alt="An icon of iris' capabilites of measure road segments."
              />
            </div>
            <div className={styles.igo_third_measure_copy} ref={measureRef}>
              Measure road segment and asset conditions over time
            </div>

            <div className={styles.igo_third_predict}>
              <img
                src={homeImages["igo_third_predict.svg"]}
                alt="An icon of irisCITY ability to predict future patterns"
              />
            </div>
            <div className={styles.igo_third_predict_copy} ref={predictRef}>
              Predict future work and planning activity
            </div>
          </div>

          <div className={styles.irisgo_first_mobile_contents}>
            <img
              src={homeImages["irisgo_first_mobile_contents.svg"]}
              alt="irisgo_first_mobile_contents"
            />
          </div>

          <div className={styles.irisgo_first_contents}>
            <div
              className={styles.irisgo_video}
              onClick={() =>
                playVideo(
                  "Website teaser - irisCITY demo from Roving irisGO™ at City of Vaughan.mp4"
                )
              }
            >
              <img
                width="589px"
                height="331px"
                src={
                  homeImages[
                    "Website teaser - irisCITY demo from Roving irisGO™ at City of Vaughan_video_cover.png"
                  ]
                }
                alt="computer vision of the irisGO"
              />
            </div>

            <div className={styles.irisgo_first_icon}>
              <img
                src={homeImages["igo_first_truck_icon.svg"]}
                alt="an icon of an irisGO enabled city truck"
              />
            </div>
            <h2 className={styles.ifc_title}>
              ARTIFICIAL INTELLIGENCE
              <br />+ COMPUTER VISION
            </h2>

            <ul>
              <li>Accurate, repeatable, objective in-field data</li>
              <li>
                Informed decision making and optimized capital expenditure in
                the long-term
              </li>
              <li>
                Assess asset condition, functionality, utilization, risk,
                performance and sustainability
              </li>
              <li>
                Expedite inspection patrols and automate work dispatch orders
              </li>
              <li>Manage maintenance evidence and statutory defence</li>
              {/* <li>Expedite MMS patrols and automate work dispatch orders</li>
                    <li>Connect and track ubiquitous city fleets and assets</li>
                    <li>Collect city wide road network sensory data points</li>
                    <li>Track real-time road hazards and public infrastructure deficiencies</li>
                    <li>Manage maintenance evidence and statutory defence</li>
                    <li>Analyze data for capital planning and urban engineering</li>
                    <li>Immutable data points for building strategic asset management policies</li> */}
            </ul>
          </div>

          <div className={styles.irisgo_second_mobile_contents}>
            <img
              src={homeImages["irisgo_second_mobile_contents.svg"]}
              alt="irisGO data collection mapping"
            />
          </div>

          <div className={styles.irisgo_second_contents}>
            <div>
              <div
                className={styles.irisgo_video}
                onClick={() =>
                  playVideo(
                    "A_brighter_Muskoka_with IRIS_&_Muskoka _Futures.mp4"
                  )
                }
              >
                <img
                  width="589px"
                  height="331px"
                  src={
                    homeImages[
                      "A_brighter_Muskoka_with_IRIS_&_Muskoka_Futures_video_cover.png"
                    ]
                  }
                  alt="skyline of a city"
                />
              </div>
            </div>
            {/* <div className="irisgo_video_iframe">
                <iframe allow="fullscreen" src="https://www.youtube.com/embed/XoDsOeq2dn4">
                </iframe>
            </div>  */}

            <div className={styles.irisgo_second_icon}>
              <img
                src={homeImages["igo_second_deeplearning_icon.svg"]}
                alt="an icon of a computer's motherboard"
              />
            </div>

            <h2 className={styles.ifc_title}>
              Our AI Algorithm does all the work for you.
            </h2>

            <p>
              Our irisGO™ technology helps redeploy undervalued city resources
              to other high value assignments and work activities by replacing
              dedicated city units, human inspectors and vehicles with an AI
              powered data collection & processing IoT system and by optimizing
              and connecting ubiquitous city assets - turning city buses,
              garbage trucks, employee vehicles and other mobile & static assets
              into “Smart”, autonomous and robust data collection systems.
            </p>
          </div>

          <div className={styles.irisgo_third_mobile_contents}>
            <img
              src={homeImages["irisgo_third_mobile_contents.svg"]}
              alt="Predictive datasets"
            />
          </div>

          <div className={styles.irisgo_third_contents}>
            <div>
              <div
                className={styles.irisgo_video}
                onClick={() =>
                  playVideo("Good Roads Conference Demo Video.mp4")
                }
              >
                <img
                  width="589px"
                  height="331px"
                  src={homeImages["Good Roads Conference Demo_video_cover.png"]}
                  alt="a screenshot of the irisCITY platform viewing a map"
                />
              </div>
            </div>
            <div className={styles.irisgo_third_icon}>
              <img
                src={homeImages["igo_third_pc_icon.svg"]}
                alt="an icon of the irisCITY dashboard"
              />
            </div>
            <h2 className={`${styles.ifc_title} ${styles.ifc_title_third}`}>
              Vision Based Solution with Private Data Automatically Redacted
            </h2>
            <p>
              The key element of irisGO™ is the ability to collect data
              automatically then transmit the captured data to administrators
              utilizing the irisCITY™ cloud reporting platform. Data flow
              includes integration with existing customer data platforms. Each
              day data is visualized with location, state of the art ESRI-based
              GIS mapping, to identify specific data points found.
              Administrators are now in a position to visualize the defect and
              make data driven decisions based on the need and location. The
              next step is to click through for immediate work order processing.
            </p>
          </div>

          {false && (
            <div className={styles.irisgo_third_mobile_contents}>
              <img
                src={homeImages["irisgo_third_mobile_contents.svg"]}
                alt="Predictive datasets"
              />
            </div>
          )}

          <div className={styles.irisgo_fourth_contents}>
            <div className={styles.irisgo_fourth_icon}>
              <img
                src={homeImages["igo_fourth_status.svg"]}
                alt="The icon of irisCity"
              />
            </div>

            <h2 className={`${styles.ifc_title} ${styles.ifc_title_fourth}`}>
              Vision Based Solution with Private Data Automatically Redacted
            </h2>

            <ul>
              <li>
                Data reporting is delivered via the irisCITY™ data platform
              </li>
              <li>
                Data integrated within existing city GIS, ERP and CRM systems
                for continued down-stream efficiencies
              </li>
              <li>
                Integrations for valuations, service level predictions,
                maintenance and capex programs
              </li>
              <li>
                Road needs studies and assessments delivered by industry leading
                consultants
              </li>
              {/* <li>Data collection is delivered via the irisCITY™ data portal</li>
                    <li>Data integrated within existing city platforms for continued down-stream efficiencies
                    </li>
                    <li>Operational awareness dashboards through spatial analysis & data science using ground
                        truth data captured by irisGO™, Geotab’s GO9 & ESRI technologies</li>  */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IrisGo;
