import { Route, Routes } from "react-router-dom";
import { routesData } from "../routesData";

const AllRoutes = () => {
  return (
    <Routes>
      {routesData.map((route, index) => (
        <Route key={index} path={route.path} element={route.component} />
      ))}
    </Routes>
  );
};

export default AllRoutes;
