import styles from "./Page4.module.css";
import importImgs from "../../../utils/importImgs";

const Page4 = () => {
  /** images imported from "/image/IRF" directory, property name is the same as the image file name */
  const IRFImages = importImgs(
    require.context("../../../image/IRF", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div className={styles.page4}>
      <div className={styles.page4Heading}>
        <p className={styles.page4Title}>DETECT - COLLECT - ASSESS - REPORT</p>
      </div>
      <div className={styles.page4LowerContainer}>
        <div className={styles.page4PictureTextContainer}>
          <img
            className={styles.page4Picture}
            src={IRFImages["page-4-pic1.png"]}
            alt="modeling vehicles through patrol shot"
          />
          <p className={styles.page4Subtitle}>Connected Vehicles</p>
          <div className={styles.page4SubtitleSpacer}></div>
          <p className={styles.page4PictureText}>
            Turn city fleets such as waste trucks and buses into ROW asset data
            collection vehicles
          </p>
        </div>
        <div className={styles.page4PictureTextContainer}>
          <img
            className={styles.page4Picture}
            src={IRFImages["page-4-pic2.png"]}
            alt="toll"
          />
          <p className={styles.page4Subtitle}>Toll Road Maintenance</p>
          <div className={styles.page4SubtitleSpacer}></div>
          <p className={styles.page4PictureText}>
            Conduct condition assessments with a higher frequency than ever
            before
          </p>
        </div>
        <div className={styles.page4PictureTextContainer}>
          <img
            className={styles.page4Picture}
            src={IRFImages["page-4-pic3.png"]}
            alt="traffic management with video"
          />
          <p className={styles.page4Subtitle}>ITS</p>
          <div className={styles.page4SubtitleSpacer}></div>
          <p className={styles.page4PictureText}>
            Digital Twin & AI integration with existing traffic management
            systems and video feeds
          </p>
        </div>
        <div className={styles.page4PictureTextContainer}>
          <img
            className={styles.page4Picture}
            src={IRFImages["page-4-pic4.png"]}
            alt="machine learning on sea ports"
          />
          <p className={styles.page4Subtitle}>Air and Sea Ports</p>
          <div className={styles.page4SubtitleSpacer}></div>
          <p className={styles.page4PictureText}>
            Continuous and automated operations monitoring and facilities
            management
          </p>
        </div>
        <div className={styles.page4PictureTextContainer}>
          <img
            className={styles.page4Picture}
            src={IRFImages["page-4-pic5.png"]}
            alt="Roads & Concessions"
          />
          <p className={styles.page4Subtitle}>Roads & Concessions</p>
          <div className={styles.page4SubtitleSpacer}></div>
          <p className={styles.page4PictureText}>
            Proactively monitor road defects and assess right-of-way assets with
            one pass
          </p>
        </div>
        <div className={styles.page4PictureTextContainer}>
          <img
            className={styles.page4Picture}
            src={IRFImages["page-4-pic6.png"]}
            alt="rails"
          />
          <p className={styles.page4Subtitle}>Transit, Rail & Tunnels</p>
          <div className={styles.page4SubtitleSpacer}></div>
          <p className={styles.page4PictureText}>
            Real-time object, event, and defect detection for operational
            efficiency
          </p>
        </div>
      </div>
    </div>
  );
};

export default Page4;
