import styles from "./Intro.module.css";

import importImgs from "../../../utils/importImgs";

const Intro = () => {
  /** images imported from "/image/home" directory, property name is the same as the image file name */
  const homeImages = importImgs(
    require.context("../../../image/home", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div className={styles.intro}>
      <div className={styles.intro_container}>
        <div className={styles.row}>
          <div className={styles.col_md}>
            <h2>
              Smart Powerful <br />
              Data Collection
            </h2>
            <p>
              Data collection seamlessly delivered from existing city vehicles
              using irisGO™ Maximize utility of city assets to enable
              significant cost savings and continuous data collection
              <br />
              Private data is NEVER captured - faces or plate numbers are
              redacted by the device at source
            </p>
          </div>

          <div className={styles.col_md}>
            <img
              className={styles.introImg}
              src={homeImages["truck.png"]}
              alt="irisGO Device mounted on a waste truck."
            />
            <p className={styles.truckCaption}>
              irisGO™ installed on city waste vehicle
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro
