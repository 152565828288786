import styles from "./AboutIRIS.module.css";
import { NavLink } from "react-router-dom";

import importImgs from "../../../utils/importImgs";

const AboutIRIS = () => {
  /** images imported from "/image/home" directory, property name is the same as the image file name */
  const homeImages = importImgs(
    require.context("../../../image/home", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div className={styles.about_iris}>
      <div className={styles.about_iris_wrap}>
        <div className={styles.about_irisgo_copy}>
          <p>
            Our solution helps cities make data driven decisions, whether its
            building better roads, finding preventive maintenance opportunities
            or creating asset inventories, irisGO™ is your complete data
            collection system.
          </p>
          <NavLink className={styles.demoButton2} replace to="/contact">
            Ask for a Demo
          </NavLink>
        </div>

        <div className={styles.about_irisgo_logo}>
          <img
            src={homeImages["about_irisgo_logo.svg"]}
            alt="The official irisGO logo in black and red"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutIRIS
