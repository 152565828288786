import styles from "./Page3.module.css";
import importImgs from "../../../utils/importImgs";

const Page3 = (props) => {
  const { playVideo } = props;

  /** images imported from "/image/IRF" directory, property name is the same as the image file name */
  const IRFImages = importImgs(
    require.context("../../../image/IRF", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div className={styles.page3}>
      <div className={styles.page3LeftSide}>
        <p className={styles.page3Title}>
          Real Time
          <br />
          Compliance & Reporting
        </p>

        <div className={styles.page3LeftPictureWithTextContainer}>
          <div className={styles.page3LeftPictureContainer}>
            <img
              className={styles.page3LeftPicture}
              src={IRFImages["page-3-pic1.png"]}
              alt="traffic signs"
            />
          </div>
          <p className={styles.page3LeftPictureText}>
            Right-of-Way Asset Location & Condition Inventories
          </p>
        </div>
        <div className={styles.page3LeftPictureWithTextContainer}>
          <div className={styles.page3LeftPictureContainer}>
            <img
              className={styles.page3LeftPicture}
              src={IRFImages["page-3-pic2.png"]}
              alt="pavement"
            />
          </div>
          <p className={styles.page3LeftPictureText}>Pavement Assessments</p>
        </div>
        <div className={styles.page3LeftPictureWithTextContainer}>
          <div className={styles.page3LeftPictureContainer}>
            <img
              className={styles.page3LeftPicture}
              src={IRFImages["page-3-pic3.png"]}
              alt="monitoring"
            />
          </div>

          <p className={styles.page3LeftPictureText}>
            Road Patrol, Concessions Maintenance & Record Keeping
          </p>
        </div>

        <div className={styles.page3LeftBottomPicturneContainer}>          
          <img className={styles.page3LeftBottomPicture}
              src={IRFImages["page-3-pic5.jpg"]}
              alt="work order summary" />
        </div>
      </div>

      <div className={styles.page3RightSide}>
        <img
          className={styles.page3RightLogo}
          src={IRFImages["page-3-logo.png"]}
          alt="safety logo"
        />
        <div className={styles.page3RightSpacer}>
          <br />
        </div>
        <p className={styles.page3RightTitle}>
          PRIVACY IS AT THE FOCUS OF EVERYTHING WE DO
        </p>
        <div>
          <br />
          <br />
        </div>
        <p className={styles.page3RightText}>
          The iris GO™ computer vision data collection device never collects private information
        </p>
        <div>
          <br />
          <br />
        </div>
        <p className={styles.page3RightText}>
          Privacy information such as faces, and license plates are redacted at the edge and is never collected
        </p>
        <div>
          <br />
          <br />
        </div>
        <p className={styles.page3RightText}>
          IRIS is supported by Dr. Ann Cavoukian, three-term privacy commissioner and author of the global Privacy by Design framework
        </p>
        <div className={styles.page3RightPictureContainer}>
          <div
            className={styles.irisgo_video}
            onClick={() => playVideo("street_demo.mp4")}
          >
            <img
              src={IRFImages["irisgo_first_video_cover.jpg"]}
              alt="computer vision of the irisGO"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page3;
