import anime from "animejs/lib/anime.es.js";

/**
 * custom hook, takes dom elements and anime effects, return anime functions
 * @param {Object} props 
 * @param {Array.<Array>} props.animeDomElementsRef dom elemets grouped by same anime effects, and anime will be applied to them 
 * @param {Array.<Ref>} props.triggerDomElementsRef dom elemets will trigger anime effects, length should be same as the array of anime dom elements 
 * @param {Array.<Object>} props.animeEffects anime effects applied to the dom elements respectively, length should be either 1 or same as the length of dom elements array. If length is 1, the anime effect will be applied to all dom elements
 * @param {Function} props.mouseOverEffects anime effects triggered by mouse over events
 * @param {Function} props.mouseOutEffects anime effects triggered by mouse out events
 * @param {Object} props.mouseOverAnimeDomElementsRef dom elements of both copy and illust, apply the anime effect triggered by mouse over and mouse out events
 * @param {Array} props.mouseOverAnimeDomElementsRef.copy length should be same as the array of trigger dom elements
 * @param {Array} [props.mouseOverAnimeDomElementsRef.illust] length should be same as the array of trigger dom elements
 * @example animeDomElementsRef: [[target_a, target_b], [target_c, target_d]]
 * @example triggerDomElementsRef: [target_a, target_b]
 * @example props.mouseOverAnimeDomElementsRef.copy: [target_a, target_b]
 * @example props.mouseOverAnimeDomElementsRef.illust: [target_a, target_b]
 * @example animeEffects:
 * [
 *   {  
 *      opacity: [
          { value: 1, duration: 100, delay: 2000 },
          { value: 0, duration: 100, delay: 2000 },
        ], 
        scale: [
          { value: 1.02, duration: 1500, delay: 1000 },
          { value: 1, duration: 1500, delay: 500 },
        ]
      },
      {
        opacity: [
          { value: 1, duration: 1500, delay: 1000 },
          { value: 0, duration: 1500, delay: 500 },
        ]
      }
    ]

 * @returns {Fuctions}  anime functions
 */
const useAnimeAutoPlayHover = (props) => {
  const {
    animeDomElementsRef,
    triggerDomElementsRef,
    animeEffects,
    mouseOverEffects,
    mouseOutEffects,
    mouseOverAnimeDomElementsRef,
  } = props;

  // build the time line object, to auto play and pause
  const timeLine = anime.timeline({
    easing: "easeInElastic",
    loop: true,
  });

  // auto play function
  const autoPlayAnime = () => {
    if (animeEffects.length === 1) {
      animeDomElementsRef.forEach((group) => {
        const tempGroup = group.map((ref) => ref.current);
        timeLine.add({
          targets: tempGroup,
          ...animeEffects[0],
        });
      });
    } else if (animeEffects.length === animeDomElementsRef.length) {
      animeDomElementsRef.forEach((group, index) => {
        const tempGroup = group.map((ref) => ref.current);
        timeLine.add({
          targets: tempGroup,
          ...animeEffects[index],
        });
      });
    }

    // when mouse over trigger elements, auto play will be paused; when mouse out, auto play will resume
    triggerDomElementsRef.forEach((ref) => {
      const domElement = ref.current;
      if (domElement) {
        domElement.addEventListener("mouseover", () => {
          timeLine.pause();
        });
        domElement.addEventListener("mouseout", () => {
          timeLine.play();
        });
      }
    });
  };

  // replace image function when triggered by trigger elements
  const illustAnime = (icon, copy, illust) => {
    // when mouse over any trigger elements, first of all make all anime elements disappear
    icon.addEventListener("mouseover", () => {
      animeDomElementsRef.forEach((group) => {
        group.forEach((ref) => {
          const domElement = ref.current;
          if (domElement) {
            domElement.style.opacity = 0;
          }
        });
      });

      // only the target displays
      mouseOverEffects(copy, illust);
    });

    // when mouse out the trigger elements, target will disappear
    icon.addEventListener("mouseout", () => {
      mouseOutEffects(copy, illust);
    });
  };

  // Solution Illustration Button Mouse over
  const illustMouseover = () => {
    if (animeDomElementsRef.length === triggerDomElementsRef.length) {
      const { copy, illust = [] } = mouseOverAnimeDomElementsRef;

      if (illust.length !== 0) {
        triggerDomElementsRef.forEach((ref, index) => {
          const iconElement = ref.current;
          const copyElement = copy[index].current;
          const illustElement = illust[index].current;
          if (iconElement && copyElement && illustElement) {
            illustAnime(iconElement, copyElement, illustElement);
          }
        });
      } else if (illust.length === 0) {
        triggerDomElementsRef.forEach((ref, index) => {
          const iconElement = ref.current;
          const copyElement = copy[index].current;
          if (iconElement && copyElement) {
            illustAnime(iconElement, copyElement);
          }
        });
      }
    }
  };

  return { autoPlayAnime, illustMouseover };
};

export default useAnimeAutoPlayHover;
