import Contact from "../pages/contact/Contact";
import EULA from "../pages/EULA/EULA";
import Home from "../pages/home/Home";
import IrisIRF from "../pages/irisIRF/IrisIRF";

/**
 * @typedef RoutesData
 * @property {string} path path for this route
 * @property {Component} component component for this route
 */

/** @type {Array.<RoutesData>} routes in array */
const routesData = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/solutions",
    component: <IrisIRF />,
  },
  {
    path: "/contact",
    component: <Contact />,
  },
  {
    path: "/EULA",
    component: <EULA />,
  },
];

export { routesData };
