import styles from "./Page5.module.css";
import importImgs from "../../../utils/importImgs";

const Page5 = () => {
  /** images imported from "/image/IRF" directory, property name is the same as the image file name */
  const IRFImages = importImgs(
    require.context("../../../image/IRF", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div className={styles.page5}>
      <div className={styles.page5Heading}>
        <p className={styles.page5Title}>USE CASES</p>
      </div>
      <div className={styles.page5TitleSpacer}>
        <br />
      </div>

      <div>
        <br />
        <br />
        <br />
      </div>
      <div className={styles.page5TextContainer}>
        <p className={styles.page5Subtitle}>
          Use Case:
          <span className={styles.page5Text}>
            {" "}
            Ongoing Road Patrol & Asset Inspection{" "}
          </span>
        </p>
        <p className={styles.page5Subtitle}>
          Deployment
          <span className={styles.page5Text}> : I-95 Florida, USA </span>
        </p>

        <div className={styles.page5TextSpacer}></div>

        <p className={styles.page5Subtitle}>
          Use Case:
          <span className={styles.page5Text}> ITS and Video Analytics </span>
        </p>
        <p className={styles.page5Subtitle}>
          Deployment:
          <span className={styles.page5Text}>
            {" "}
            Tollway Concessions, Brazil{" "}
          </span>
        </p>

        <div className={styles.page5TextSpacer}></div>

        <p className={styles.page5Subtitle}>
          Use Case:
          <span className={styles.page5Text}>
            {" "}
            Gondola Object & People Tracking{" "}
          </span>
        </p>
        <p className={styles.page5Subtitle}>
          Deployment:
          <span className={styles.page5Text}> Sky-liner Gondola, USA </span>
        </p>

        <div className={styles.page5TextSpacer}></div>

        <p className={styles.page5Subtitle}>
          Use Case:
          <span className={styles.page5Text}>
            {" "}
            Ongoing Road Patrol & Asset Inspection{" "}
          </span>
        </p>
        <p className={styles.page5Subtitle}>
          Deployment:
          <span className={styles.page5Text}>
            {" "}
            Highway Concessions, France{" "}
          </span>
        </p>

        <div className={styles.page5TextSpacer}></div>

        <p className={styles.page5Subtitle}>
          Use Case:
          <span className={styles.page5Text}>
            {" "}
            Tunnel inspection using motorcycles{" "}
          </span>
        </p>
        <p className={styles.page5Subtitle}>
          Deployment:
          <span className={styles.page5Text}>
            {" "}
            Tunnel Concessions, Australia{" "}
          </span>
        </p>

        <div className={styles.page5TextSpacer}></div>

        <p className={styles.page5Subtitle}>
          Use Case:
          <span className={styles.page5Text}>
            {" "}
            Roads & Sidewalk Inspection using golf carts{" "}
          </span>
        </p>
        <p className={styles.page5Subtitle}>
          Deployment:
          <span className={styles.page5Text}>
            {" "}
            Urban Road Networks, Canada{" "}
          </span>
        </p>
      </div>

      <div className={styles.page5Footer}>
        <div className={styles.page5FooterHeading}>
          <div>
            <div className={styles.whiteSpacer}></div>
            <p className={styles.page5FooterHeadingText}>TESTIMONIAL</p>
          </div>
          <img
            className={styles.page5LogoImg}
            src={IRFImages["page-5-logo.png"]}
            alt="InfraChallenge 2021"
          />
        </div>
        <div className={styles.page5FooterTextContainer}>
          <p className={styles.page5FooterTextInQuotes}>
            "The Italian G20 Presidency applauds IRIS for winning InfraChallenge
            2021. Such an innovative solution is instrumental to making sure
            future road infrastructure is sustainable, resilient, smart and
            accessible to all. "
          </p>
          <p className={styles.page5FooterSpeaker}>
            Head of G7/G20 Coordination Unit, Italian Ministry
            <br />
            of Economy and Finance, Lawrence Bartolomucci
          </p>
        </div>
      </div>
    </div>
  );
};

export default Page5;
