import styles from "./Page7.module.css";
import importImgs from "../../../utils/importImgs";
import { NavLink } from "react-router-dom";

const Page7 = () => {
  /** images imported from "/image/IRF" directory, property name is the same as the image file name */
  const IRFImages = importImgs(
    require.context("../../../image/IRF", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div className={styles.page7}>
      <div className={styles.page7Heading}>
        <p className={styles.page7Title}>PROUD WINNERS</p>
        <div className={styles.page7HeadingLogosTextContainer}>
          <div className={styles.page7HeadingLogoWithText}>
            <img
              src={IRFImages["page-7-logo1.png"]}
              className={styles.page7HeadingLogo}
              alt="infraChallenge Winner 2021"
            />
            <p className={styles.page7HeadingText}>
              Global Infrastructure Hub
              <br />
              infraChallenge Winner 2021
            </p>
          </div>
          <div className={styles.page7HeadingLogoWithText}>
            <a
              href="https://irfnet.ch/2022/10/13/iris-winner-of-the-2022-edition-of-the-irf-start-up-label/"
              target="_blank"
            >
              <img
                src={IRFImages["page-7-logo2.png"]}
                className={`${styles.page7HeadingLogo} ${styles.IRFLogo}`}
                alt="International Road Federation Start-Up Label Winner 2022"
              />
            </a>
            {/* <img
              src={IRFImages["page-7-logo2.png"]}
              className={styles.page7HeadingLogo}
              alt="International Road Federation Start-Up Label Winner 2022"
            /> */}
            <p className={styles.page7HeadingText}>
              International Road Federation
              <br />
              Start-Up Label Winner 2022
            </p>
          </div>
          <div className={styles.page7HeadingLogoWithText}>
            <img
              src={IRFImages["page-7-logo3.png"]}
              className={styles.page7HeadingLogo}
              alt="Smart 50 Awards 2022 Smart Cities Connect"
            />
            <p className={styles.page7HeadingText}>
              Smart 50 Awards 2022
              <br />
              Smart Cities Connect
            </p>
          </div>
        </div>
      </div>
      <div className={styles.page7Contact}>
        <p className={styles.page7Subtitle}>CONTACT</p>
        <div className={styles.page7ContactSpacer}></div>
        <div className={styles.page7ContactInfo}>
          <div className={styles.page7ContactInfoOnePerson}>
            <p className={styles.page7Text}>
              EMIL SYLVESTER RAMOS,
              <br />
              CEO
            </p>
            <a href="mailto:emilr@irisradgroup.com">
              <p className={styles.page7EmailText}>emilr@irisradgroup.com</p>
            </a>
          </div>
          <div className={styles.page7ContactInfoOnePerson}>
            <p className={styles.page7Text}>
              BRIAN KEANEY,
              <br />
              VP STRATEGIC PARTNERSHIPS
            </p>

            <a href="mailto:briank@irisradgroup.com">
              <p className={styles.page7EmailText}>briank@irisradgroup.com</p>
            </a>
          </div>
        </div>
      </div>
      <div className={styles.page7HeadOffice}>
        <p className={styles.page7Subtitle}>GLOBAL HEAD OFFICE</p>
        <p className={styles.page7TextBold}>
        Greater Toronto Area
        </p>
        <p className={styles.page7Text}>
          Unit 801, 5500 N Service Rd. Burlington,
          <br />
          Ontario L7L6W6 Canada
        </p>
      </div>
      <div className={styles.page7Partners}>
        <p className={styles.page7Subtitle}>PARTNERS</p>
        <div className={styles.page7ContactSpacer}></div>
        <div className={styles.page7PartnersLogosContainer}>
          <img
            src={IRFImages["page-7-logo4.png"]}
            className={styles.page7PartnersLogo}
            alt="esri logo"
          />
          <img
            src={IRFImages["page-7-logo5.png"]}
            className={styles.page7PartnersLogo}
            alt="telus logo"
          />
          <img
            src={IRFImages["page-7-logo6.png"]}
            className={styles.page7PartnersLogo}
            alt="geotab logo"
          />
        </div>
      </div>
      <div className={styles.page7FollowUs}>
        <div className={styles.page7FollowUsTitle}>
          <p className={styles.page7Subtitle}>FOLLOW US</p>
          <div className={styles.page7ContactSpacer}></div>
        </div>
        <div className={styles.page7FollowUsLinks}>
          <div className={styles.page7FollowUsOneLink}>
            <img
              src={IRFImages["page-7-logo7.png"]}
              className={styles.page7FollowUsIcon}
              alt="linkedin icon"
            />
            <a href="https://www.linkedin.com/company/irisradgroupinc/">
              <p className={styles.page7Text}>
                www.linkedin.com/company/irisradgroupinc
              </p>
            </a>
          </div>
          {/* <div className={styles.page7FollowUsOneLink}>
                  <img src={IRFImages["page-7-logo8.png"]} className={styles.page7FollowUsIcon} alt="youtube icon"/>                        
                        <a href="https://youtube.com/channel/irisradgroup">
                            <p className={styles.page7Text}>
                                www.youtube.com/channel/irisradgroup
                            </p>
                        </a>
                </div>  */}
          <div className={styles.page7FollowUsOneLink}>
            <img
              src={IRFImages["page-7-logo9.png"]}
              className={styles.page7FollowUsIcon}
              alt="global icon"
            />
            <NavLink replace to="/">
              <p className={styles.page7Text}>www.irisradgroup.com</p>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page7;
