import styles from "./Footer.module.css"
import { NavLink } from "react-router-dom";
import importImgs from "../../utils/importImgs";

const Footer = () => {
    /** images imported from "/image/IRF" directory, property name is the same as the image file name */
  const footerImages = importImgs(
    require.context("../../image/footer", false, /\.(png|jpe?g|svg)$/)
  );

    return (
        <div className={styles.footer}>
        <div className={styles.ft_conatiner}>
          <div className={styles.sns_item}>
            <a href="https://www.facebook.com/irisradgroup/">
              <img src={footerImages["facbook_icon.svg"]} alt="facebook" />
            </a>
            <a href="https://twitter.com/irisradgroup/">
              <img src={footerImages["twitter_icon.svg"]} alt="twitter" />
            </a>
            <a href="https://www.youtube.com/channel/UC7J6Nt-LKnvmBPLxVl7Wj0w">
              <img src={footerImages["youtube_icon.svg"]} alt="youtube" />
            </a>
            <a href="https://www.linkedin.com/company/irisradgroupinc/">
              <img src={footerImages["linkedin_icon.svg"]} alt="linkedIn" />
            </a>
          </div>

          <div className={styles.ft_item}>
            <div className={styles.ft_contact}>
              <h2>LOCATIONS</h2>
              <ul>
                <li>
                  <b>Canada:</b>
                </li>
                <li>5500 North Service Rd #801, Burlington, ON L7L 6W6</li>
                <li>
                  <a href="mailto:info@irisradgroup.com">
                    info@irisradgroup.com
                  </a>
                </li>

                <li>
                  <b>United States:</b>
                </li>
                <li>1325 6th Ave, New York, NY 10019, United States</li>
                <li>
                  <a href="mailto:usa.info@irisradgroup.com">
                    usa.info@irisradgroup.com
                  </a>
                </li>

                <li>
                  <b>Brazil:</b>
                </li>
                <li>Praça XV de Novembro, 20, Rio de Janeiro, RJ 20010-010</li>
                <li>
                  <a href="mailto:info@irisradgroup.com">
                    info@irisradgroup.com
                  </a>
                </li>
              </ul>
            </div>
            <div className={styles.ft_company}>
              <h2>Company</h2>
              <ul>
                <li>
                  <NavLink replace to="/">
                    irisGO
                  </NavLink>
                </li>
                <li>
                  <NavLink replace to="/solutions">
                    IRIS
                  </NavLink>
                </li>
                <li>
                  <a href="/news/">NEWS</a>
                </li>
              </ul>
            </div>
            <div className={styles.ft_auth}>
              <div className={styles.auth_icon}>
                <img src={footerImages["auth_icon.svg"]} alt="auth icon" />
              </div>
              <ul>
                <li>
                  We acknowledge the support of the Natural Sciences and
                  Engineering Research Council of Canada (NSERC).
                </li>
                <li>
                  Cette recherche a été financée par le Conseil de recherches en
                  sciences naturelles et en génie du Canada (CRSNG).
                </li>
                <li>Toronto Lobbyist Registration 35831S-1</li>
                <li>Subject Matter Registration Number: SM26390 |</li>
                <li>
                  Grants / Funding;Technology;Transportation - Roads / Bridges
                </li>
              </ul>
            </div>
            <div className={styles.copyright}>
              <img src={footerImages["footer_iris_icon.svg"]} alt="" />
              <ul>
                <li>
                  Copyright © 2022 IRIS R&D Group Inc. All rights reserved.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Footer