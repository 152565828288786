import { useRef } from "react";
import NavBar from "../../components/navbar/NavBar";
import Footer from "../../components/footer/Footer";

import "../../css/googleFont.css";
import styles from "./Contact.module.css";
import importImgs from "../../utils/importImgs";

import { postData } from "../../utils/apiFetch";

const Contact = () => {
  /** images imported from "/image/IRF" directory, property name is the same as the image file name */
  const contactImages = importImgs(
    require.context("../../image/contact", false, /\.(png|jpe?g|svg)$/)
  );

  // obtain DOM elements with useRef hook
  const clientForm = useRef(null);
  const fontTitle = useRef(null);
  const contactDescription = useRef(null);
  const successMessage = useRef(null);
  const footer = useRef(null);

  /** function triggered when clicking the submit button */
  const handelSubmit = (event) => {
    event.preventDefault();
    if (clientForm.current.checkValidity() === false) {
      event.stopPropagation();
      clientForm.current.classList.add("was-validated");
    } else {
      let formData = {};

      // create an array with the form-control class, and create a json object called formData.
      // only create the field if the input is not empty
      const formControlNodeList =
        document.getElementsByClassName("form-control");

      Array.from(formControlNodeList).forEach((input) => {
        if (input.value !== "") formData[input.id] = input.value;
      });

      // create a subject field to identify where the request came from
      formData["subject"] = "Website Contact: " + formData["name"];

      if (formData.name && formData.email && formData.message) {
        clientForm.current.style.display = "none";
        fontTitle.current.style.display = "none";
        contactDescription.current.style.display = "none";
        successMessage.current.style.display = "block";
        footer.current.style.paddingTop = 0;
        window.scrollTo({ top: 0, behavior: "smooth" });

        postData(
          "https://post.api.irisradgroup.com/api/send_contact/",
          formData
        ).then((data) => {
          console.log(data);
        });
      }
    }
  };

  return (
    <div className={styles.page_container}>
      <div className={styles.head_container}>
        <NavBar />

        <div className={styles.main_hero}>
          <div className={styles.hero_image_wrap}>
            <img src={contactImages["hero_image.jpg"]} alt="hero" />
          </div>
          <div className={styles.main_hero_text_wrap}>
            <div className={styles.main_hero_title}>Contact Us</div>
          </div>

          <div className={styles.onHero_title}>
            <div className={styles.onHero_title_wrap}>
              <div className={styles.onHero_font_title} ref={fontTitle}>
                Let's Talk!
              </div>
              <div className={styles.onHero_font_copy}>
                <p ref={contactDescription}>
                  {" "}
                  Learn more about our on demand end to end AI-enabled smart
                  city and asset reporting technology.
                </p>

                <h3 ref={successMessage} className={styles.emailSent}>
                  Thanks for your interest! We will be in touch shortly.
                </h3>

                <form ref={clientForm} onSubmit={handelSubmit} noValidate>
                  <div className={styles.form_group}>
                    <label htmlFor="name">
                      Name<small style={{ color: "red" }}>*</small>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${styles.form_control}`}
                      id="name"
                      placeholder="Your name"
                      required
                    />
                    <div className="invalid-feedback">Please add a name.</div>
                  </div>

                  <div className={styles.form_group}>
                    <label htmlFor="email">
                      Email Address<small style={{ color: "red" }}>*</small>
                    </label>
                    <input
                      type="email"
                      className={`form-control ${styles.form_control}`}
                      id="email"
                      placeholder="Your email"
                      // regex to make sure the input email is valid
                      pattern="^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.[a-zA-Z]\w{1,})+$"
                      required
                    />
                    <div className="invalid-feedback">
                      Please add a valid email address.
                    </div>
                  </div>

                  <div className={styles.form_group}>
                    <label htmlFor="city">Municipality/Organization</label>
                    <input
                      type="text"
                      className={`form-control ${styles.form_control}`}
                      id="city"
                      placeholder="Where do you work?"
                    />
                  </div>

                  <div className={styles.row}>
                    <div className={`${styles.form_group} ${styles.col_sm}`}>
                      <label htmlFor="phone">Phone</label>

                      <input
                        type="tel"
                        className={`form-control ${styles.form_control}`}
                        id="phone"
                        // the regex matches the following
                        // 123-456-7890
                        // (123) 456-7890
                        // 123 456 7890
                        // 123.456.7890
                        // +91 (123) 456-7890
                        pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
                      />
                      <div className="invalid-feedback">
                        Please input a valid phone number or leave this field
                        blank.
                      </div>
                    </div>
                    <div className={`${styles.form_group} ${styles.col_sm}`}>
                      <label htmlFor="job">Position</label>
                      <input
                        type="text"
                        className={`form-control ${styles.form_control}`}
                        id="job"
                        placeholder="What do you do?"
                      />
                    </div>
                  </div>

                  <div className={styles.form_group}>
                    <div className={styles.form_group}>
                      <label htmlFor="message">
                        Message<small style={{ color: "red" }}>*</small>
                      </label>
                      <textarea
                        className={`form-control ${styles.form_control}`}
                        id="message"
                        rows="3"
                        placeholder="I want to know more."
                        maxLength="400"
                        minLength="10"
                        required
                      ></textarea>
                      <div className="invalid-feedback">
                        Message needs minimum 10 characters.
                      </div>
                    </div>

                    <button type="submit" className={styles.btn_info}>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.contactFooter} ref={footer}>
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
