import styles from "./ThreeSolution.module.css";
import { useRef, useEffect } from "react";

import importImgs from "../../../utils/importImgs";
import useAnimeAutoPlayHover from "../../../hooks/useAnimeAutoPlayHover";

const ThreeSolution = () => {
  /** images imported from "/image/home" directory, property name is the same as the image file name */
  const homeImages = importImgs(
    require.context("../../../image/home", false, /\.(png|jpe?g|svg)$/)
  );

  // DOM elements for three solutions
  const tsBoxTruckRef = useRef(null);
  const tsBoxDeeplearningRef = useRef(null);
  const tsBoxIriscityPCRef = useRef(null);
  const irisgo_truck_ref = useRef(null);
  const target_irisgo_truck_ref = useRef(null);
  const deeplearning_ref = useRef(null);
  const target_deeplearning_ref = useRef(null);
  const iriscity_pc_ref = useRef(null);
  const target_iriscity_pc_ref = useRef(null);

  // props for useAnimeAutoPlayHover custom hook
  const animeDomElementsRef = [
    [target_irisgo_truck_ref],
    [target_deeplearning_ref],
    [target_iriscity_pc_ref],
  ];
  const triggerDomElementsRef = [
    tsBoxTruckRef,
    tsBoxDeeplearningRef,
    tsBoxIriscityPCRef,
  ];
  const animeEffects = [
    {
      opacity: [
        { value: 1, duration: 1500, delay: 1000 },
        { value: 0, duration: 1500, delay: 500 },
      ],
      scale: [
        { value: 1.02, duration: 1500, delay: 1000 },
        { value: 1, duration: 1500, delay: 500 },
      ],
    },
  ];
  const mouseOverEffects = (copy, illust) => {
    copy.style.transform = "scale(1.02)";
    copy.style.opacity = 1;
  };
  const mouseOutEffects = (copy, illust) => {
    copy.style.transform = "scale(1)";
    copy.style.opacity = 0;
  };
  const mouseOverAnimeDomElementsRef = {
    copy: [
      target_irisgo_truck_ref,
      target_deeplearning_ref,
      target_iriscity_pc_ref,
    ],
  };
  const hookProps = {
    animeDomElementsRef,
    triggerDomElementsRef,
    animeEffects,
    mouseOverEffects,
    mouseOutEffects,
    mouseOverAnimeDomElementsRef,
  };

  const { autoPlayAnime, illustMouseover } = useAnimeAutoPlayHover(hookProps);

  // when component mounts, apply the defined functions
  useEffect(() => {
    autoPlayAnime();
    illustMouseover();
  }, []);

  return (
    <div className={styles.three_solution}>
      <div className={styles.ts_box} ref={tsBoxTruckRef}>
        <div className={styles.ts_box_image_wrap}>
          <div
            className={styles.ts_box_image_overlay_position}
            ref={irisgo_truck_ref}
          >
            <img
              src={homeImages["three_solution_hw.jpg"]}
              alt="highway with cars"
            />
            <div
              className={styles.ts_box_overray_icon}
              ref={target_irisgo_truck_ref}
            >
              <img
                src={homeImages["irisgo_truck_icon.svg"]}
                alt="An icon of a vehicle using an irisGO device."
              />
            </div>
          </div>
          {/* <div className={styles.ts_box_text}>
                    <h2>Road Monitoring</h2>
                    <p>Efficient inspection and proactive maintenance</p>
                </div> */}
          <div className={styles.ts_box_text}>
            <h2>Road Monitoring</h2>
            <p>
              AI-enabled Fully Automated MMS 239/02 Compliance and Record
              Keeping
            </p>
          </div>
        </div>
      </div>

      <div className={styles.ts_box} ref={tsBoxDeeplearningRef}>
        <div className={styles.ts_box_image_wrap}>
          <div
            className={styles.ts_box_image_overlay_position}
            ref={deeplearning_ref}
          >
            <img
              src={homeImages["three_solution_dl.jpg"]}
              alt="A side view of a metal bridge"
            />
            <div
              className={styles.ts_box_overray_icon}
              ref={target_deeplearning_ref}
            >
              <img
                src={homeImages["deeplearning_icon.svg"]}
                alt="An iccon of a checcklist of asset management."
              />
            </div>
          </div>
          {/* <div className={styles.ts_box_text}>
                    <h2>Asset Management</h2>
                    <p>Data-Driven Evidence-Based Decision Making</p>
                </div> */}
          <div className={styles.ts_box_text}>
            <h2>Asset Management</h2>
            <p>
              AI-enabled Pavement and Right of Way Condition Assessments and
              Inventories
            </p>
          </div>
        </div>
      </div>

      <div className={styles.ts_box} ref={tsBoxIriscityPCRef}>
        <div className={styles.ts_box_image_wrap}>
          <div
            className={styles.ts_box_image_overlay_position}
            ref={iriscity_pc_ref}
          >
            <img
              src={homeImages["three_solution_pc.jpg"]}
              alt="A computer showing data vizualization of a dashboard"
            />
            <div
              className={styles.ts_box_overray_icon}
              ref={target_iriscity_pc_ref}
            >
              <img
                src={homeImages["iriscity_pc_icon.svg"]}
                alt="An icon of the irisCITY dashboard solution."
              />
            </div>
          </div>
          {/* <div className={styles.ts_box_text}>
                    <h2>Predictive Intelligence</h2>
                    <p>Deep learning AI with computer vision</p>
                </div> */}
          <div className={styles.ts_box_text}>
            <h2>Predictive Intelligence</h2>
            {/* <p>Intergrate seamlessly to existing GIS, ERP and asset management tools</p> */}
            <p>
              Integrate seamlessly with existing GIS, ERP and asset management
              tools
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeSolution;
