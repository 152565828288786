import styles from "./Page6.module.css";
import importImgs from "../../../utils/importImgs";
import { useEffect, useState } from "react";

const Page6 = (props) => {
  const { playVideo } = props;

  /** images imported from "/image/IRF" directory, property name is the same as the image file name */
  const IRFImages = importImgs(
    require.context("../../../image/IRF", false, /\.(png|jpe?g|svg)$/)
  );

  // responsive html elements controlled by screen with
  const [screenWidth, setScreenWidth] = useState(1024);

  // when component mounts, get the window's screen size and then set state to decide how the page is displayed
  useEffect(()=> {
    setScreenWidth(window.innerWidth)
  },[])

  // when window resizes, set state of the screen size dynamically
  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setScreenWidth(window.innerWidth);
      });
    };
  });

  // screen width decide how this section renders
  const PictureAndTextSection = () => {
    if (screenWidth >= 666) {
      return (
        <div>
          <div className={styles.page6UpperPicturesContainer}>
            <img
              src={IRFImages["page-6-pic1.png"]}
              className={styles.page6UpperPictureLeft}
              alt="irisGO dashcam"
            />
            <img
              src={IRFImages["page-6-pic2.png"]}
              className={styles.page6UpperPictureRight}
              alt="irisCity demo"
            />
          </div>
          <div className={styles.page6UpperPicturesTextContainers}>
            <div className={styles.page6TextContainer}>
              <p className={styles.page6Text}>
                <img
                  src={IRFImages["irisgo-logo.png"]}
                  className={styles.page6Logo}
                  alt="irisGO logo"
                />
                is a plug and play dash camera that can be installed on patrol
                vehicles, buses, waste trucks and in static locations such as
                traffic lights in intersections
              </p>
            </div>
            <div className={styles.page6TextContainer}>
              <p className={styles.page6Text}>
                <img
                  src={IRFImages["iriscity-logo.png"]}
                  className={styles.page6Logo}
                  alt="irisCity logo"
                />
                is a data visualization dashboard that integrates with client
                work management systems, ERP , CRM including Maintenance
                Decision Support Systems
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.smallScreenUpperSection}>
          <div className={styles.pictureTextContainer}>
            <div className={styles.page6UpperPicturesContainer}>
              <img
                src={IRFImages["page-6-pic1.png"]}
                className={styles.page6UpperPicture1}
                alt="irisGO dashcam"
              />
            </div>
            <div className={styles.page6TextContainer}>
              <p className={styles.page6Text}>
                <img
                  src={IRFImages["irisgo-logo.png"]}
                  className={styles.page6Logo}
                  alt="irisGO logo"
                />
                is a plug and play dash camera that can be installed on patrol
                vehicles, buses, waste trucks and in static locations such as
                traffic lights in intersections
              </p>
            </div>
          </div>
          <div className={styles.pictureTextContainer}>
            <div className={styles.page6UpperPicturesContainer}>
              <img
                src={IRFImages["page-6-pic2.png"]}
                className={styles.page6UpperPicture2}
                alt="irisCity demo"
              />
            </div>
            <div className={styles.page6TextContainer}>
              <p className={styles.page6Text}>
                <img
                  src={IRFImages["iriscity-logo.png"]}
                  className={styles.page6Logo}
                  alt="irisCity logo"
                />
                is a data visualization dashboard that integrates with client
                work management systems, ERP , CRM including Maintenance
                Decision Support Systems
              </p>
            </div>
          </div>
        </div>
      );
    }
  };  

  return (
    <div className={styles.page6}>
      <div className={styles.page6Heading}>
        <p className={styles.page6Title}>
          OUR SMART CITY HARDWARE & DATA PLATFORMS
        </p>
      </div>
      {PictureAndTextSection()}

      <div className={styles.page6Subtitle}>
        <p className={styles.page6SubtitleText}>Learn More:</p>
      </div>

      <div className={styles.page6LowerPicturesContainer}>
        <div
          className={styles.irisgo_video}
          onClick={() =>
            playVideo("IRIS Digital Twins & Video Analytics Product Suite.mp4")
          }
        >
          <img
            src={
              IRFImages[
                "IRIS_Digital_Twins_Video_Analytics_Product_Suite_video_cover.png"
              ]
            }
            alt="IRIS_Digital_Twins_Video_Analytics_Product_Suite"
          />
        </div>
        <div className={styles.page6LowerPictureContainer}>
          <a
            href="https://www.gihub.org/news/ai-solution-for-infrastructure-maintenance-wins-gi-hub-s-innovation-competition/"
            target="_blank"
          >
            <img
              src={IRFImages["page-6-pic4.png"]}
              className={styles.page6LowerPicture}
              alt="IRIS winning InfraChallenge 2021, with CEO Emil's headshot"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Page6;
