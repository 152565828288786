import styles from "./Page2.module.css";
import importImgs from "../../../utils/importImgs";

const Page2 = () => {
  /** images imported from "/image/IRF" directory, property name is the same as the image file name */
  const IRFImages = importImgs(
    require.context("../../../image/IRF", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div className={styles.page2}>
      <div className={styles.page2LeftSide}>
        <img
          className={styles.page2LeftPicture}
          src={IRFImages["page-2-pic1.jpg"]}
          alt="irisGO dashcam installed on patrol vehicle"
        />

        <div className={styles.page2LeftTextContainer}>
          <p className={styles.page2LeftPictureText}>
            Above: iris GO™ dashcam installed on patrol vehicle in the Greater
            Toronto Area, Canada
          </p>
          <div className={styles.page2LeftTitleContainer}>
            <div className={styles.whiteSpacer}></div>
            <p className={styles.page2LeftTitleUpperLine}>Where we are</p>
            <p className={styles.page2LeftTitleLowerLine}>TODAY</p>
          </div>
        </div>
      </div>

      <div className={styles.page2RightSide}>
        <div className={styles.page2RightHeading}>
          <p className={styles.page2Title}>A TRUE GAME CHANGER</p>
        </div>
        <p>
          <br />
        </p>
        <div className={styles.page2TitleSpacer}></div>
        <p className={styles.page2RightText}>
          IRIS is an infrastructure technology & smart cities company. The company is an internationally recognized pioneer in solving the most difficult data collection challenges facing the roads, tunnels, and transport infrastructure sector today. Our mission is to help cities, concessionaires and infrastructure owners increase road safety, reduce on-road emissions, and extend the life of the world's ageing infrastructure through proactive maintenance, data, and AI.
          <br />
          <br />
          The irisGO™ smart camera is placed within existing patrol fleets, waste trucks or on static assets such as light poles, where it uses AI & Machine Vision to automatically detect objects of interest (encroachment, potholes, broken signs, barriers, and other defects) and its attributes including class, type, material, size, quantity, and other measurements that feed into reporting and analytics dashboards. <span className={styles.textBold}>This automated solution enables to you to make key capital and operational decisions at a substantially lower cost than standard processes used today</span> - which rely on expensive pavement survey trucks, complex lidar data, manual highway patrols and pen and paper curb side inspections.
          <br />
          <br />
          Additional benefits to the technology include frequent reporting on
          industry standard pavement assessment indexes, right-of-way asset
          inventories, condition assessments and real time mobility data such as
          vehicle, ped and bike counts, all in one pass.
        </p>
      </div>
    </div>
  );
};

export default Page2;
