import styles from "./GraphSection.module.css"

const GraphSection = () => {
    return (
        <div className={styles.iris_graph}>
          <div className={styles.ig_container}>
            <div className={`${styles.ig_20} ${styles.ig_copy}`}>
              <h2>30+</h2>
              <p>
                The number of irisGO™ devices deployed with clients in both
                static and mobile applications are growing at incredible rates,
                gathering real-time data with ease and efficiency
              </p>
            </div>
            <div className={`${styles.ig_10m} ${styles.ig_copy}`}>
              <h2>10M+</h2>
              <p>
                The number of actionable data points gathered for our clients{" "}
                <br />
                grows daily
              </p>
            </div>
            <div className={`${styles.ig_52k} ${styles.ig_copy}`}>
              <h2>52,000KM</h2>
              <p>
                The amount of kilometers covered with irisGO™ data collection is
                increasing daily
              </p>
            </div>
          </div>
        </div>
    )
}

export default GraphSection