import NavBar from "../../components/navbar/NavBar";

import "../../css/googleFont.css";
import styles from "./IRISCompanyProfileIRF.module.css";
import mainHeroMp4 from "../../image/main_hero.mp4";

import { useState } from "react";
import importImgs from "../../utils/importImgs";
import VideoModal from "../../components/videoModal/VideoModal";

import Page2 from "../../components/IRFsections/page2/Page2";
import Page3 from "../../components/IRFsections/page3/Page3";
import Page4 from "../../components/IRFsections/page4/Page4";
import Page5 from "../../components/IRFsections/page5/Page5";
import Page6 from "../../components/IRFsections/page6/Page6";
import Page7 from "../../components/IRFsections/page7/Page7";

const IrisIRF = () => {  
  const IRFMp4 = importImgs(
    require.context("../../contents", false, /\.(mp4)$/)
  );

  // set state of the playing video
  const [videoSrc, setVideoSrc] = useState("");
  // set state of the video playing or not
  const [videoOn, setVideoOn] = useState(false);

  /**
   * function to play the video, with given video name
   * @param {string} videoName video name
   */
  const playVideo = (videoName) => {
    setVideoSrc(videoName);
    setVideoOn(true);
  };

  return (
    <div>
      <div className={styles.head_container}>
        <NavBar />
        <div className={styles.headingBackground}>
          <video
            preload="auto"
            autoPlay="autoplay"
            loop="loop"
            muted="muted"
            width="100%"
            height="auto"
          >
            <source src={mainHeroMp4} type="video/mp4" />
          </video>
        </div>
      </div>

      <Page2 />
      
      <Page3 playVideo={playVideo} />

      <Page4 />

      <Page5 />

      <Page6 playVideo={playVideo} />
      
      <Page7 />

      <VideoModal
        videoOn={videoOn}
        setVideoOn={setVideoOn}
        videoSrc={IRFMp4[videoSrc]}
      />
    </div>
  );
};

export default IrisIRF;
