/**
 * function to import multiple images from given directory
 * @param {Function} r function which takes directory and file's extension, e.g require.context("../../image/IRF", false, /\.(png|jpe?g|svg)$/)
 * @returns {Object}
 */
const importImgs = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

export default importImgs;
