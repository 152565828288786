import { useEffect } from "react";
import styles from "./Testimonial.module.css";
import Carousel from "react-bootstrap/Carousel";

import importImgs from "../../../utils/importImgs";

/**
 * @typedef TestmonialCarousel
 * @property {number} id id of the carousel
 * @property {string} quotes quotes content
 * @property {string} imageName name of the image file, including extension
 * @property {string} name name of person
 * @property {HTMLElement} position position of the person
 */

/** @type {Array.<TestmonialCarousel>} */
const TESTMONIAL_CAROUSEL = [
  {
    id: 0,
    quotes: `“The IRIS technology is currently deployed in municipal fleets and waste trucks traversing the City of Hamilton collecting real time mobility, infrastructure and asset management data that helped in the physical, operational, financial, and governance of our municipal infrastructure and covid-19 related programs.”`,
    imageName: "cyrus_headshot.jpg",
    name: "Cyrus Tehrani",
    position: (
      <h6>
        City of Hamilton
        <br />
        Chief Digital Officer
      </h6>
    ),
  },
  {
    id: 1,
    quotes: `“I believe that IRIS's irisGO™ technology and gathering real time data about road conditions and hazards is useful for a city like Burlington to identify risk and prioritize work - especially during this emergency.”`,
    imageName: "mark_headshot.jpg",
    name: "Mark Steffler",
    position: (
      <h6>
        City of Burlington
        <br />
        Manager, Innovation and Partnerships
      </h6>
    ),
  },
  {
    id: 2,
    quotes: `“The irisCITY™ mapping interface saves hundreds of municipal staff hours each month. The tools provide an intuitive interface for reviewing, classifying and resolving road deficiencies…”`,
    imageName: "matt_headshot.jpg",
    name: "Matt Pietryszyn",
    position: (
      <h6>
        City of Hamilton
        <br />
        GIS specialist
      </h6>
    ),
  },
  {
    id: 3,
    quotes: `“IRIS R&D is a great Burlington company which is developing new and exciting technological solutions that look to make a significant difference ... they are an example of the kind of Ontario-made businesses that will help lead the province back to jobs and prosperity”`,
    imageName: "effie_headshot.jpg",
    name: "Ms. Effie Triantafilopoulos",
    position: (
      <h6>
        Oakville North, Burlington
        <br />
        M.P.P.
      </h6>
    ),
  },
  {
    id: 4,
    quotes: `“We are thrilled to have IRIS R&D located in Burlington. Their innovative technologies are helping to support organizations, like TechPlace, adapt to COVID-19 guidelines and stay open so people can continue working safely.”`,
    imageName: "marianne_headshot.jpg",
    name: "Her Worship Marianne Meed Ward",
    position: (
      <h6>
        Burlington
        <br />
        Mayor, City of Burlington
      </h6>
    ),
  },
  {
    id: 5,
    quotes: `“Not only do we get the long-term economic benefits of bringing a company like IRIS to Burlington that has that fantastic, let's face it, exponential growth potential with all the applications of your technology that can be put out there. But we also get benefits here in Burlington.”`,
    imageName: "anita_headshot.jpg",
    name: "Anita Cassidy",
    position: (
      <h6>
        Burlington
        <br />
        Executive Director at Burlington Economic Development Corporation.
      </h6>
    ),
  },
  {
    id: 6,
    quotes: `“This is a great partnership and this is why when we say at the city of Guelph “To act more business like” we actually mean it. Having companies like IRIS embed themselves in our operations helps is in turn learn from the private sector too.”`,
    imageName: "camGuthrie.jpg",
    name: "Cam Guthrie",
    position: (
      <h6>
        Guelph
        <br />
        Mayor, City of Guelph
      </h6>
    ),
  },
  {
    id: 7,
    quotes: `“IRIS was able to automate our MMS process from start to finish bringing downstream cost savings and efficiencies all while protecting the privacy of London residents.”`,
    imageName: "david_sumner.jpg",
    name: "David Sumner",
    position: (
      <h6>
        City of London
        <br />
        Supervisor, Roads
      </h6>
    ),
  },
  {
    id: 8,
    quotes: `“This work will enhance the data quality for a significant asset category in the City's portfolio, strengthening the quality of the analysis and improving our understanding of our asset base, allowing for continuous and proactive road monitoring.”`,
    imageName: "jessica_angers.jpeg",
    name: "Jessica Angers",
    position: (
      <h6>
        City of Guelph
        <br />
        Manager, Cooporate Asset and Project Management, Engineering and
        Transportation Serviers
      </h6>
    ),
  },
  {
    id: 9,
    quotes: `“Congratulations to IRIS on their use of artificial intelligence to create a solution for public assets. Infrastructure maintenance is a ubiquitous challenge for governments around the world and IRIS's technology has the potential to improve the safety and resilience of roads and potentially other assets.”`,
    imageName: "marie_lam.jpeg",
    name: "Marie Lam - Frendo",
    position: (
      <h6>
        CEO
        <br />
        Global Infrastructure Hub
      </h6>
    ),
  },
];

const Testimonial = () => {
  /** images imported from "/image/home" directory, property name is the same as the image file name */
  const homeImages = importImgs(
    require.context("../../../image/home", false, /\.(png|jpe?g|svg)$/)
  );

  // when component mounts, apply the defined functions
  useEffect(() => {
    // carousel indicators style
    const carouselIndicators = document.querySelector(".carousel-indicators");
    carouselIndicators.style.bottom = "-50px";
    carouselIndicators.style["margin-bottom"] = "0";
  }, []);

  return (
    <div className={styles.testmonial}>
      <div className={styles.tm_container}>
        <div className={styles.g_font_title}>
          <h2>Clients continue to be impressed with our solution...</h2>
        </div>

        <Carousel
          nextLabel=""
          prevLabel=""
          className={styles.carousel_bootstrap}
          interval={8000}
        >
          {TESTMONIAL_CAROUSEL.map((oneSlide) => {
            const { id, quotes, imageName, name, position } = oneSlide;
            return (
              <Carousel.Item
                key={id}
                className={`${styles.tm_item_ms} g_font_copy`}
              >
                <p>{quotes}</p>
                <img src={homeImages[imageName]} alt="head shot" />

                <h5>{name}</h5>
                {position}
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonial;
