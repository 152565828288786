import { useRef } from "react";
import { NavLink } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import "../../css/googleFont.css";
import styles from "./NavBar.module.css";
import officialIRISLogo from "../../image/nav/iris_go_white_2x_augus_14_2021.png";

const NavBar = () => {
  // DOM elements for open mobile nav and close mobile nav
  const mobileNavRef = useRef(null);
  const mobileOpenBtnRef = useRef(null);

  // open mobile nav menu
  const openMobileMenu = () => {
    if (mobileNavRef.current) {
      mobileNavRef.current.style.width = "70%";
    }
    if (mobileOpenBtnRef.current) {
      mobileOpenBtnRef.current.classList.toggle("active");
    }
  };

  // close mobile nav menu
  const closeMobileMenu = () => {
    if (mobileNavRef.current) {
      mobileNavRef.current.style.width = "0%";
    }
    if (mobileOpenBtnRef.current) {
      mobileOpenBtnRef.current.classList.toggle("active");
    }
  };

  return (
    <div className={styles.naviContainer}>
      <div className={styles.navi}>
        <div className={styles.naviInner}>
          <NavLink className={styles.naviLogo} replace to="/">
            <img
              src={officialIRISLogo}
              aria-label="irisGO"
              alt="The official IRIS white logo"
            />
          </NavLink>

          <div className={styles.naviContents}>
            <div
              className={`${styles.naviMenu} ${styles.navFont} ${styles.naviLinkIris}`}
            >
              <NavLink
                to="/"
                className={(navData) => (navData.isActive ? styles.active : "")}
                end
              >
                ABOUT
              </NavLink>
            </div>
            <div
              className={`${styles.naviMenu} ${styles.navFont} ${styles.naviLinkIris}`}
            >
              <NavLink
                replace
                to="/solutions"
                className={(navData) => (navData.isActive ? styles.active : "")}
              >
                SOLUTIONS
              </NavLink>
            </div>
            <div
              className={`${styles.naviMenu} ${styles.navFont} ${styles.naviLinkNews}`}
            >
              {/* <a href="/news/">NEWS</a> */}
              <a
                href="https://www.linkedin.com/company/irisradgroupinc/"
                target="_blank"
              >
                NEWS
              </a>
            </div>
            <div
              className={`${styles.naviMenu} ${styles.navFont} ${styles.naviLinkClient}`}
            >
              <a
                href="https://dashboard.iriscity.irisradgroup.com"
                target="_blank"
              >
                CLIENT&nbsp;LOGIN
              </a>
            </div>

            <DropdownButton
              className={`${styles.naviMenuDropdown} ${styles.navFont}`}
              title="Language"
              variant="danger"
              menuVariant="dark"
            >
              <Dropdown.Item
                className={`${styles.dropdownItem} ${styles.disabled}`}
                href="#"
                disabled
              >
                French
              </Dropdown.Item>
              <Dropdown.Item
                className={styles.dropdownItem}
                href="https://www.iforcom-se.jp/iris/index.html"
              >
                Japanese
              </Dropdown.Item>
            </DropdownButton>
          </div>

          <div
            id="mobileNav"
            className={styles.hamMenuToggle}
            ref={mobileNavRef}
          >
            <a
              href="#"
              className={styles.mobileCloseBtn}
              onClick={closeMobileMenu}
            >
              &times;
            </a>

            <div className={styles.overlayContent}>
              <ul id="menu">
                <NavLink to="/" end>
                  <li>ABOUT</li>
                </NavLink>
                <NavLink replace to="/solutions">
                  <li>SOLUTIONS</li>
                </NavLink>
                <a
                  href="https://www.linkedin.com/company/irisradgroupinc/"
                  target="_blank"
                >
                  <li>NEWS</li>
                </a>
                <a
                  href="https://dashboard.iriscity.irisradgroup.com"
                  target="_blank"
                >
                  <li>CLIENT&nbsp;LOGIN</li>
                </a>
                <li>
                  <DropdownButton
                    className={`${styles.naviMenuDropdown} ${styles.navFont}`}
                    title="Language"
                    variant="danger"
                    menuVariant="dark"
                  >
                    <Dropdown.Item
                      className={`${styles.dropdownItem} ${styles.disabled}`}
                      href="#"
                      disabled
                    >
                      French
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.dropdownItem}
                      href="https://www.iforcom-se.jp/iris/index.html"
                    >
                      Japanese
                    </Dropdown.Item>
                  </DropdownButton>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={styles.mobileOpenBtn}
            onClick={openMobileMenu}
            ref={mobileOpenBtnRef}
          >
            &#9776;
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
